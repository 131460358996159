<template>
  <header
    class="top-0 z-header"
    :class="{
      'fixed w-full': invert,
      'sticky b-b b-grey-80 bg-white': !invert,
    }"
    data-scroll-el="header"
  >
    <div
      v-if="invert"
      class="absolute-0 -z-1"
      style="background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.6) 75%); height: 15rem"
    />
    <div ref="navigationRef" class="pointer-within flex items-center py-4 container between">
      <div class="pointer-within w-1/3 flex justify-start">
        <vf-mega-menu
          v-model="megaMenuOpened"
          :invert="invert || searchOpened"
          :mega-menu="megaMenu"
          :utility-links="links"
        />
      </div>
      <base-link to="/" class="z-1">
        <vf-logo :invert="invert || searchOpened || (!$viewport.md && megaMenuOpened)" />
      </base-link>
      <div class="pointer-within w-1/3 flex items-center justify-end gap-2">
        <vf-search
          v-model="searchOpened"
          :invert="invert || (!$viewport.md && megaMenuOpened)"
        />
        <base-link to="/cart" class="relative">
          <span class="sr-only">{{ $t.cart }}</span>
          <vf-icon
            name="bag"
            size="xl"
            pos="right -0.4375rem top -0.0625rem"
            :class="{ 'c-white': invert || searchOpened || (!$viewport.md && megaMenuOpened) }"
          />
          <client-only>
            <span
              v-if="cart?.totalItems"
              class="absolute right-0 flex justify-center fw-medium lh-2"
              style="bottom: 0.3125rem; width: 1.0625rem"
              :class="{ 'c-white': invert || searchOpened || (!$viewport.md && megaMenuOpened) }"
            >
              {{ cart.totalItems }}
            </span>
          </client-only>
        </base-link>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
defineProps<{
  invert?: boolean
}>()

const cart = useCartStore()
const router = useRouter()
const header = useHeaderStore()
const navigationRef = toRef(header.refs, 'navigation')

const { data: links } = await useApi().internal.getUtility()
const megaMenu = await useCms().getMegaMenu()

const megaMenuOpened = ref(false)
const searchOpened = ref(false)

router.beforeEach(() => {
  if (megaMenuOpened.value)
    megaMenuOpened.value = false

  if (searchOpened.value)
    searchOpened.value = false
})
</script>
